@import "../../assets/fonts/tronwallet-fonts.css";

.messages {
  text-align: center;
  margin: 0 -20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.container {
  width: auto;
  max-width: 768px;
  padding: 0;
}
.header {
  font-family: "Montserrat", sans-serif;
  text-align: left;

  margin-bottom: 20px;
  margin-left: 12px;

  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
}

.swipeableViews {
  width: 100%;
  background-color: white;
}

button,
button:focus {
  outline: none;
  font-family: "Montserrat", sans-serif;
}

img {
  max-width: 100%;
}

.messageInput {
  margin: 0 10px;

  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  padding: 30px;
  margin-bottom: 30px;
  position: relative;

  textarea {
    width: 100%;
    height: 100px;
    background: #fdfdfd;
    border: 1px solid #eee !important;
    border-radius: 2px !important;
    resize: none;
    padding: 20px;
    outline: none !important;
    text-align: left;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    .warning {
      flex: 1;
      color: #f44336;
      font-weight: 500;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      padding-right: 20px;
      text-align: right;
    }

    .sendButton:not(.disabled) {
      cursor: pointer;
      opacity: 1;
    }

    .sendButton:not(.disabled):hover {
      background: #0062cc;
      transform: translateY(-2px);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    }

    .sendButton:not(.disabled):focus {
      background: #0973e5;
    }
  }

  &::after {
    content: "Requesting...";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    visibility: hidden;
    opacity: 0;
    transition: all 200ms ease;
  }

  &.loading::after {
    opacity: 1;
    visibility: visible;
  }

  .accountLabel {
    display: inline-block;
    width: 200px;
    text-align: right;
  }

  .accountData {
    display: inline-block;
    margin-left: 10px;
  }

  input {
    padding-left: 10px;
    margin-left: 10px;
    display: inline-block;
  }

  label {
    display: inline-block;
    width: 140px;
    text-align: right;
  }
}

.MuiBox-root {
  background: linear-gradient(180deg, #222345 0%, rgba(34, 35, 69, 0) 100%);
  border-radius: 24px 24px 0 0;
}

// .MuiDialogTitle-root {
//     flex: 0 0 auto;
//     margin: 0;
//     padding: 16px 24px 0 !important;
// }

// .MuiTypography-h6 {
//     font-size: 1.10rem !important;
//     font-family: "Rubik","Montserrat", "Helvetica", "Arial", sans-serif  !important;
//     font-weight: 500;
//     line-height: 1.2  !important;
//     letter-spacing: 0.0075em
// }

// .MuiDialogContent-root {
//     flex: 1 1 auto;
//     padding: 0px 24px !important;
//     overflow-y: auto;
//     -webkit-overflow-scrolling: touch;
// }

// .MuiTypography-body1 {
//     font-size: 1rem;
//     font-family: "Rubik","Montserrat", "Helvetica", "Arial", sans-serif !important;
//     font-weight: 400;
//     line-height: 1.2 !important;
//     letter-spacing: 0.00938em;
// }

@-webkit-keyframes btn-loading /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes btn-loading {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
.btn-loading {
  -webkit-animation: btn-loading 1s linear infinite;
  -moz-animation: btn-loading 1s linear infinite;
  -ms-animation: btn-loading 1s linear infinite;
  -o-animation: btn-loading 1s linear infinite;
  animation: btn-loading 1s linear infinite;
}
