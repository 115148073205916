@import "../../assets/fonts/tronwallet-fonts.css";

.apr {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: right;

  h5 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: #4f5185;
    margin: 0;
  }

  p {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }
}
button {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.balance {
  margin-bottom: 15px;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }
}
.content-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.input-title {
  font-family: "Montserrat", sans-serif;

  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  color: #7b7db2;
  margin: 0;
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 14px;
  border: 1px solid #7b7db2;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 18px;

  input {
    border: none;
    width: 100%;
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
    background-color: transparent;
    text-align: left;
    outline: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  div {
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aa33b5;
    font-weight: 700;
    line-height: 15px;
    padding-top: 5px;
    font-size: 13px;
    cursor: pointer;
  }
}

.countdown {
  font-family: "Rubik", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-top: 16px;
  }
}

.info-container {
  display: flex;
  flex-direction: column;

  div {
    font-family: "Montserrat", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #27284e;
    height: 60px;
    margin-bottom: 2px;
    border-radius: 4px;
    padding: 12px;

    span {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      color: #c6c7eb;
      margin: 0;
    }

    p {
      font-family: "Rubik", sans-serif;
      padding: auto;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.separator {
  height: 1px;
  border: 1px solid #27284e;
  margin: 37px 0 32px 0;
}

.withdraw-alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  padding: 8px 12px 8px 8px;
  background: rgba(255, 227, 128, 0.4);
  border-radius: 8px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  mix-blend-mode: normal;
  margin-bottom: 13px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background: rgba(255, 227, 145, 0.4);
    border-radius: 4px;
    min-width: 20px;
    padding: 10px;
    margin-right: 10px;
  }
}
.alertDeposit {
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  mix-blend-mode: normal;
  margin-bottom: 10px;
  color: #f84960;
  margin-top: -6px;
}
