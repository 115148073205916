@font-face {
  font-family: 'tronwallet';
  src: url('./tronwallet.eot?52415963');
  src: url('./tronwallet.eot?52415963#iefix') format('embedded-opentype'),
    url('./tronwallet.ttf?52415963') format('truetype'),
    url('./tronwallet.svg?52415963#tronwallet') format('svg');
  font-weight: normal;
  font-style: normal;
}

.tw-icon {
  font-family: 'tronwallet', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  color: white;

  /* width: 1em;
    margin-right: .2em; */
  text-align: center;

  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1.4em;

  /* Animation center compensation - margins should be symmetric */

  /* remove if not needed */

  /* margin-left: .6em; */

  /* You can be more comfortable with increased icons size */
  font-size: 25px;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */

  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow,
.-right,
.-right-arrow,
.-navigation-right,
.-arrows::before {
  content: '\21';
} /* '!' */
.icon-votes-1::before {
  content: '\22';
} /* '&quot;' */
.icon-reorder::before {
  content: '\23';
} /* '#' */
.icon-sorting::before {
  content: '\24';
} /* '$' */
.icon-face-id::before {
  content: '\25';
} /* '%' */
.icon-fingerprint::before {
  content: '\26';
} /* '&amp;' */
.icon-depth::before {
  content: '\27';
} /* ''' */
.icon-triangle-down::before {
  content: '\28';
} /* '(' */
.icon-triangle-up::before {
  content: '\29';
} /* ')' */
.icon-line-chart::before {
  content: '\2a';
} /* '*' */
.icon-candle::before {
  content: '\2b';
} /* '+' */
.icon-certified::before {
  content: '\2c';
} /* ',' */
.icon-arrow-up::before {
  content: '\2d';
} /* '-' */
.icon-switch::before {
  content: '\e800';
} /* '' */
.icon-bank-locker,
.-bank-vault,
.-safe-box,
.-locker,
.-protected::before {
  content: '\e801';
} /* '' */
.icon-qr-code::before {
  content: '\e802';
} /* '' */
.icon-basket,
.-carry-bag,
.-shopping,
.-purchase,
.-bag::before {
  content: '\e803';
} /* '' */
.icon-paste::before {
  content: '\e804';
} /* '' */
.icon-notebook::before {
  content: '\e805';
} /* '' */
.icon-close::before {
  content: '\e806';
} /* '' */
.icon-lock::before {
  content: '\e807';
} /* '' */
.icon-cash-out,
.-request-money,
.-withdraw,
.-atm,
.-cash::before {
  content: '\e808';
} /* '' */
.icon-dollar::before,
.-currency,
.-money,
.-cash,
.-coin::before {
  content: '\e809';
} /* '' */
.icon-envelope,
.-letter,
.-mail,
.-e-mail,
.-message::before {
  content: '\e80a';
} /* '' */
.icon-graph,
.-bar,
.-chart,
.-statistics,
.-analytics::before {
  content: '\e80b';
} /* '' */
.icon-graph,
.-stats,
.-analytics,
.-growth,
.-chart::before {
  content: '\e80c';
} /* '' */
.icon-money,
.-currency,
.-note,
.-cash,
.-capital::before {
  content: '\e80d';
} /* '' */
.icon-pie,
.-chart,
.-graph,
.-statistics,
.-analytics::before {
  content: '\e80e';
} /* '' */
.icon-save,
.-donate,
.-give-money,
.-cash-on-delivery,
.-request-money::before {
  content: '\e80f';
} /* '' */
.icon-savings,
.-cash,
.-bag,
.-investment,
.-installment::before {
  content: '\e810';
} /* '' */
.icon-shop,
.-store,
.-retail,
.-business,
.-e-commerce::before {
  content: '\e811';
} /* '' */
.icon-visa,
.-card,
.-credit,
.-debit,
.-international::before {
  content: '\e812';
} /* '' */
.icon-wallet,
.-money,
.-cash,
.-balance,
.-purse::before {
  content: '\e813';
} /* '' */
.icon-add-contact::before {
  content: '\e814';
} /* '' */
.icon-participate-1::before {
  content: '\e815';
} /* '' */
.icon-guarantee::before {
  content: '\e816';
} /* '' */
.icon-unfreeze::before {
  content: '\e817';
} /* '' */
.icon-vote::before {
  content: '\e818';
} /* '' */
.icon-transfer-angle-up::before {
  content: '\e819';
} /* '' */
.icon-transfer-up::before {
  content: '\e81a';
} /* '' */
.icon-transfer-down::before {
  content: '\e81b';
} /* '' */
.icon-arrow,
.-right,
.-next,
.-forward,
.-move::before {
  content: '\e81c';
} /* '' */
.icon-tw-logo::before {
  content: '\e81d';
} /* '' */
.icon-arrow,
.-right,
.-share,
.-curved,
.-forward::before {
  content: '\e81e';
} /* '' */
.icon-arrow,
.-up,
.-button,
.-upside,
.-direction::before {
  content: '\e81f';
} /* '' */
.icon-bow,
.-arrow,
.-right,
.-feather,
.-quiver::before {
  content: '\e820';
} /* '' */
.icon-controller,
.-arrow,
.-joy-pad,
.-directions,
.-track-pad::before {
  content: '\e821';
} /* '' */
.icon-controller,
.-left,
.-right,
.-arrows,
.-horizontal-scroll::before {
  content: '\e822';
} /* '' */
.icon-coverage,
.-merge,
.-arrow,
.-connect,
.-join::before {
  content: '\e823';
} /* '' */
.icon-cross,
.-arrow,
.-up-down,
.-shuffle,
.-swap::before {
  content: '\e824';
} /* '' */
.icon-diverge,
.-path,
.-split,
.-divide,
.-arrow::before {
  content: '\e825';
} /* '' */
.icon-diverge,
.-split,
.-arrow,
.-divide,
.-flow::before {
  content: '\e826';
} /* '' */
.icon-double-sided,
.-arrow,
.-curved,
.-path,
.-circuit::before {
  content: '\e827';
} /* '' */
.icon-notifications::before {
  content: '\e828';
} /* '' */
.icon-transfer-angle-down::before {
  content: '\e829';
} /* '' */
.icon-auction,
.-bid,
.-gavel,
.-judge,
.-sale::before {
  content: '\e82a';
} /* '' */
.icon-freeze-1::before {
  content: '\e82b';
} /* '' */
.icon-share::before {
  content: '\e82c';
} /* '' */
.icon-send-1::before {
  content: '\e82d';
} /* '' */
.icon-transfer::before {
  content: '\e82e';
} /* '' */
.icon-edit::before {
  content: '\e82f';
} /* '' */
.icon-log-in,
.-entry,
.-in,
.-sign-in,
.-arrow::before {
  content: '\e830';
} /* '' */
.icon-log-out,
.-exit,
.-out,
.-arrow,
.-sign-out::before {
  content: '\e831';
} /* '' */
.icon-maximize,
.-expand,
.-enlarge,
.-arrow,
.-resize::before {
  content: '\e832';
} /* '' */
.icon-merge,
.-inward,
.-collide,
.-compress,
.-arrows::before {
  content: '\e833';
} /* '' */
.icon-minimize,
.-converge,
.-reduce,
.-size,
.-arrow::before {
  content: '\e834';
} /* '' */
.icon-minimize,
.-scale-down,
.-resize,
.-reduce,
.-shrink::before {
  content: '\e835';
} /* '' */
.icon-minimize,
.-screen,
.-arrow,
.-collapse,
.-shrink::before {
  content: '\e836';
} /* '' */
.icon-network,
.-arrow,
.-up-dowm,
.-mobile-data,
.-send-receive::before {
  content: '\e837';
} /* '' */
.icon-next,
.-media,
.-step-forward,
.-move,
.-forward::before {
  content: '\e838';
} /* '' */
.icon-redirect,
.-mised-call,
.-arrow,
.-rebound,
.-divert::before {
  content: '\e839';
} /* '' */
.icon-refresh,
.-reload,
.-update,
.-sync,
.-rounded-arrow::before {
  content: '\e83a';
} /* '' */
.icon-refresh,
.-sync,
.-update,
.-arrow,
.-reload::before {
  content: '\e83b';
} /* '' */
.icon-delete::before {
  content: '\e83c';
} /* '' */
.icon-info-balloon::before {
  content: '\e83d';
} /* '' */
.icon-copy::before {
  content: '\e83e';
} /* '' */
.icon-scan-part-2::before {
  content: '\e83f';
} /* '' */
.icon-scan-part-1::before {
  content: '\e840';
} /* '' */
.icon-agenda-filtro::before {
  content: '\e841';
} /* '' */
.icon-bandwidth::before {
  content: '\e842';
} /* '' */
.icon-menu::before {
  content: '\e843';
} /* '' */
.icon-two-way,
.-right,
.-left,
.-arrows,
.-double::before {
  content: '\e844';
} /* '' */
.icon-upload,
.-arrow,
.-export,
.-send,
.-transfer::before {
  content: '\e845';
} /* '' */
.icon-antenna,
.-radio,
.-signal,
.-wi-fi,
.-hotspot::before {
  content: '\e846';
} /* '' */
.icon-calls-blocked,
.-no-call,
.-phone,
.-disabled,
.-no-connection::before {
  content: '\e847';
} /* '' */
.icon-cloud,
.-data,
.-server,
.-weather,
.-online::before {
  content: '\e848';
} /* '' */
.icon-communication,
.-chat,
.-conversation,
.-messaging,
.-text::before {
  content: '\e849';
} /* '' */
.icon-diary,
.-contact,
.-address,
.-organizer,
.-book::before {
  content: '\e84a';
} /* '' */
.icon-dish,
.-satellite,
.-signal,
.-receiver,
.-tower::before {
  content: '\e84b';
} /* '' */
.icon-download,
.-server,
.-data,
.-install,
.-update::before {
  content: '\e84c';
} /* '' */
.icon-e-mail,
.-letter,
.-envelope,
.-post,
.-mail::before {
  content: '\e84d';
} /* '' */
.icon-head-phone,
.-speaker,
.-listen,
.-music,
.-audio::before {
  content: '\e84e';
} /* '' */
.icon-inbox,
.-drawer,
.-container,
.-archive,
.-mail-box::before {
  content: '\e84f';
} /* '' */
.icon-exchange-1::before {
  content: '\e850';
} /* '' */
.icon-dapps-1::before {
  content: '\e851';
} /* '' */
.icon-star::before {
  content: '\e852';
} /* '' */
.icon-check::before {
  content: '\e853';
} /* '' */
.icon-star-fill::before {
  content: '\e854';
} /* '' */
.icon-padlock::before {
  content: '\e855';
} /* '' */
.icon-smart-contract::before {
  content: '\e856';
} /* '' */
.icon-votes::before {
  content: '\e857';
} /* '' */
.icon-screen,
.-monitor,
.-desktop,
.-tv,
.-display::before {
  content: '\e858';
} /* '' */
.icon-smart,
.-phone,
.-cell-phone,
.-mobile,
.-touch-screen::before {
  content: '\e859';
} /* '' */
.icon-speaker,
.-loud,
.-sound,
.-announcement,
.-offer::before {
  content: '\e85a';
} /* '' */
.icon-text,
.-message,
.-sms,
.-chat,
.-bubble::before {
  content: '\e85b';
} /* '' */
.icon-tower,
.-signal,
.-network,
.-mobile,
.-antenna::before {
  content: '\e85c';
} /* '' */
.icon-upload,
.-server,
.-data,
.-send,
.-cloud::before {
  content: '\e85d';
} /* '' */
.icon-web-cam,
.-spy-cam,
.-camera,
.-cctv,
.-conference::before {
  content: '\e85e';
} /* '' */
.icon-alarm,
.-time,
.-alert,
.-remind,
.-wake-up::before {
  content: '\e85f';
} /* '' */
.icon-auto,
.-rotate,
.-display,
.-portrait,
.-landscape::before {
  content: '\e860';
} /* '' */
.icon-blank,
.-empty,
.-file,
.-doc,
.-document::before {
  content: '\e861';
} /* '' */
.icon-bluetooth,
.-connection,
.-wireless,
.-transfer,
.-radio-signal::before {
  content: '\e862';
} /* '' */
.icon-bucket,
.-drop,
.-paint,
.-color,
.-fill::before {
  content: '\e863';
} /* '' */
.icon-transactions::before {
  content: '\e864';
} /* '' */
.icon-settings::before {
  content: '\e865';
} /* '' */
.icon-send::before {
  content: '\e866';
} /* '' */
.icon-receive::before {
  content: '\e867';
} /* '' */
.icon-pay::before {
  content: '\e868';
} /* '' */
.icon-participate::before {
  content: '\e869';
} /* '' */
.icon-freeze::before {
  content: '\e86a';
} /* '' */
.icon-filter::before {
  content: '\e86b';
} /* '' */
.icon-circuit,
.-electric-board,
.-path,
.-three-nodes,
.-connection::before {
  content: '\e86c';
} /* '' */
.icon-circuit,
.-electric-board,
.-path,
.-two-nodes,
.-connection::before {
  content: '\e86d';
} /* '' */
.icon-circuit,
.-electric-board,
.-path,
.-two-parallel-nodes,
.-connection::before {
  content: '\e86e';
} /* '' */
.icon-circuit,
.-network,
.-path,
.-hub,
.-connection::before {
  content: '\e86f';
} /* '' */
.icon-circuit,
.-strategy,
.-path,
.-nodes,
.-connection::before {
  content: '\e870';
} /* '' */
.icon-color,
.-picker,
.-selector,
.-filler,
.-eye-dropper::before {
  content: '\e871';
} /* '' */
.icon-cover-flow,
.--horizontal--layout-thumbnail::before {
  content: '\e872';
} /* '' */
.icon-double-tick,
.-read,
.-select-all,
.-message-read,
.-delivered::before {
  content: '\e873';
} /* '' */
.icon-equaliser,
.-audio,
.-sound-wave,
.-waves,
.-beat::before {
  content: '\e874';
} /* '' */
.icon-exit,
.-full-screen,
.-display,
.-minimise,
.-scale-down::before {
  content: '\e875';
} /* '' */
.icon-fire,
.-flame,
.-trend,
.-hot,
.-spark::before {
  content: '\e876';
} /* '' */
.icon-full-screen,
.-resize,
.-enlarge,
.-capture,
.-frame::before {
  content: '\e877';
} /* '' */
.icon-gear,
.-cog,
.-setup,
.-update,
.-settings::before {
  content: '\e878';
} /* '' */
.icon-hand-gesture,
.-horizontal-scroll,
.-move-right,
.-move-left,
.-adjust::before {
  content: '\e879';
} /* '' */
.icon-hand-gesture,
.-swipe,
.-right-to-left,
.-left,
.-towards-left::before {
  content: '\e87a';
} /* '' */
.icon-heart-beat,
.-pulse,
.-monitor,
.-display,
.-fitness::before {
  content: '\e87b';
} /* '' */
.icon-history,
.-last-used,
.-past,
.-undo,
.-rewind::before {
  content: '\e87c';
} /* '' */
.icon-history,
.-locked,
.-protected,
.-cant-undo,
.-cant-revert-back::before {
  content: '\e87d';
} /* '' */
.icon-home,
.-house,
.-building,
.-hut,
.-homepage::before {
  content: '\e87e';
} /* '' */
.icon-hotspot,
.-signal,
.-antenna,
.-radio,
.-network::before {
  content: '\e87f';
} /* '' */
.icon-infinity,
.-loop,
.-infinite,
.-forever,
.-endless::before {
  content: '\e880';
} /* '' */
.icon-list,
.-notes,
.-align,
.-left,
.-paragraph::before {
  content: '\e881';
} /* '' */
.icon-loader,
.-loading,
.-progress,
.-circular-lines,
.-preloader::before {
  content: '\e882';
} /* '' */
.icon-message,
.-bubble,
.-chat,
.-text,
.-sms::before {
  content: '\e883';
} /* '' */
.icon-message,
.-chat,
.-bubble,
.-text,
.-rounded::before {
  content: '\e884';
} /* '' */
.icon-message,
.-chatting,
.-chat,
.-bubble,
.-typing::before {
  content: '\e885';
} /* '' */
.icon-message,
.-disabled,
.-not-allowed,
.-denied,
.-not-sent::before {
  content: '\e886';
} /* '' */
.icon-message,
.-text,
.-sms,
.-chat,
.-bubble::before {
  content: '\e887';
} /* '' */
.icon-minimize,
.-exit-fullscreen,
.-resize,
.-small-screen,
.-receive::before {
  content: '\e888';
} /* '' */
.icon-notify,
.-message,
.-notification,
.-alert,
.-new::before {
  content: '\e889';
} /* '' */
.icon-paint,
.-brush,
.-color,
.-art,
.-drawing::before {
  content: '\e88a';
} /* '' */
.icon-phone,
.-vibration,
.-ring,
.-vibrate,
.-mobile::before {
  content: '\e88b';
} /* '' */
.icon-pinch,
.-expand,
.-screen,
.-enlarge,
.-hand-gesture::before {
  content: '\e88c';
} /* '' */
.icon-refresh,
.-recycle,
.-triangle,
.-trash,
.-reuse::before {
  content: '\e88d';
} /* '' */
.icon-save,
.-copy,
.-floppy,
.-disk,
.-hard-drive::before {
  content: '\e88e';
} /* '' */
.icon-selection,
.-tool,
.-dotted-square,
.-dotted-line,
.-highlight::before {
  content: '\e88f';
} /* '' */
.icon-share,
.-expand,
.-enlarge,
.-bigger,
.-full-screen::before {
  content: '\e890';
} /* '' */
.icon-shout-out,
.-speaker,
.-offer,
.-announcement,
.-loud::before {
  content: '\e891';
} /* '' */
.icon-sleep,
.-mode,
.-night,
.-moon,
.-asleep::before {
  content: '\e892';
} /* '' */
.icon-swipe-up,
.-hand-gesture,
.-top,
.-scroll-up,
.-move-up::before {
  content: '\e893';
} /* '' */
.icon-swipe,
.-left,
.-hand,
.-gesture,
.-right-to-left::before {
  content: '\e894';
} /* '' */
.icon-exchange::before {
  content: '\e895';
} /* '' */
.icon-tap,
.-hand,
.-gesture,
.-touch,
.-double-tap::before {
  content: '\e896';
} /* '' */
.icon-to-do,
.-calendar,
.-event,
.-schedule,
.-date::before {
  content: '\e897';
} /* '' */
.icon-touch,
.-hand,
.-gesture,
.-tap,
.-double-tap::before {
  content: '\e898';
} /* '' */
.icon-unlock,
.-open,
.-unlocked,
.--password,
.-lock::before {
  content: '\e899';
} /* '' */
.icon-usb,
.-data-transfer,
.-pen-drive,
.-hard-disk,
.-storage::before {
  content: '\e89a';
} /* '' */
.icon-add,
.-address,
.-location,
.-new,
.-pin::before {
  content: '\e89b';
} /* '' */
.icon-address,
.-pin,
.-verified,
.-location,
.-verify-::before {
  content: '\e89c';
} /* '' */
.icon-crosshair,
.-cross-hair,
.-location,
.-gps,
.-target::before {
  content: '\e89d';
} /* '' */
.icon-direction,
.-path,
.-board,
.-sign,
.-way::before {
  content: '\e89e';
} /* '' */
.icon-discover,
.-compass,
.-direction,
.-explore,
.-navigation::before {
  content: '\e89f';
} /* '' */
.icon-earth,
.-globe,
.-planet,
.-world,
.-universe::before {
  content: '\e8a0';
} /* '' */
.icon-gps,
.-disabled,
.-location,
.-crosshair,
.-navigation::before {
  content: '\e8a1';
} /* '' */
.icon-gps,
.-locator,
.-crosshair,
.-location,
.-map::before {
  content: '\e8a2';
} /* '' */
.icon-location,
.-map,
.-road,
.-navigation,
.-area::before {
  content: '\e8a3';
} /* '' */
.icon-location,
.-pin,
.-map,
.-address,
.-gps::before {
  content: '\e8a4';
} /* '' */
.icon-location,
.-pin,
.-map,
.-gps,
.-point::before {
  content: '\e8a5';
} /* '' */
.icon-location,
.-pin,
.-map,
.-ios,
.-gps::before {
  content: '\e8a6';
} /* '' */
.icon-navigation,
.-direction,
.-navigator,
.-location,
.-gps::before {
  content: '\e8a7';
} /* '' */
.icon-pin,
.-paper,
.-attach,
.-map,
.-marker::before {
  content: '\e8a8';
} /* '' */
.icon-remove,
.-delete,
.-address,
.-location,
.-point::before {
  content: '\e8a9';
} /* '' */
.icon-add,
.-new,
.-button,
.-plus,
.-create::before {
  content: '\e8aa';
} /* '' */
.icon-atom,
.-network,
.-connect,
.-molecule,
.-community::before {
  content: '\e8ab';
} /* '' */
.icon-atom,
.-science,
.-network,
.-synergy,
.-nucleus::before {
  content: '\e8ac';
} /* '' */
.icon-badge,
.-quality,
.-premium,
.-award,
.-achievement::before {
  content: '\e8ad';
} /* '' */
.icon-ball,
.-base-ball,
.-fun,
.-sports,
.-game::before {
  content: '\e8ae';
} /* '' */
.icon-basket,
.-shopping,
.-cart,
.-e-commerce,
.-store::before {
  content: '\e8af';
} /* '' */
.icon-bell,
.-mute,
.-silent,
.-sound-off,
.-do-not-disturb-::before {
  content: '\e8b0';
} /* '' */
.icon-bell,
.-ring,
.-notify,
.-notification,
.-alert::before {
  content: '\e8b1';
} /* '' */
.icon-bookmark,
.-save,
.-ribbon,
.-remember,
.-store::before {
  content: '\e8b2';
} /* '' */
.icon-building,
.-apartment,
.-tower,
.-office,
.-city::before {
  content: '\e8b3';
} /* '' */
.icon-bulb,
.-thought,
.-idea,
.-creative,
.-light::before {
  content: '\e8b4';
} /* '' */
.icon-camera,
.-photo,
.-gallery,
.-photography,
.-picture::before {
  content: '\e8b5';
} /* '' */
.icon-caution,
.-alert,
.-triangle,
.-error,
.-disabled::before {
  content: '\e8b6';
} /* '' */
.icon-chat,
.-message,
.-heart,
.-dating,
.-love::before {
  content: '\e8b7';
} /* '' */
.icon-clip,
.-attachment,
.-paper,
.-attach,
.-office::before {
  content: '\e8b8';
} /* '' */
.icon-clock,
.-time,
.-wall-clock,
.-alarm,
.-timer::before {
  content: '\e8b9';
} /* '' */
.icon-config,
.-settings,
.-adjust,
.-equalizer,
.preferences::before {
  content: '\e8ba';
} /* '' */
.icon-copy,
.-cut,
.-move,
.-duplicate,
.-file::before {
  content: '\e8bb';
} /* '' */
.icon-cube,
.-box,
.-trerd,
.-sqaure,
.-volume::before {
  content: '\e8bc';
} /* '' */
.icon-cursor,
.-pointer,
.-interface,
.-click,
.--arrow-::before {
  content: '\e8bd';
} /* '' */
.icon-dashboard,
.-performance,
.-speed,
.-gauge,
.-speedometer::before {
  content: '\e8be';
} /* '' */
.icon-delete,
.-box,
.-container,
.-trash,
.-dust-bin::before {
  content: '\e8bf';
} /* '' */
.icon-delete,
.-trash,
.-dust-bin,
.-remove,
.-recycle-bin::before {
  content: '\e8c0';
} /* '' */
.icon-diamond,
.-ruby,
.-jewel,
.-gem,
.-valuable::before {
  content: '\e8c1';
} /* '' */
.icon-disable,
.-not-allowed,
.-no-access,
.-restricted,
.-stop::before {
  content: '\e8c2';
} /* '' */
.icon-edit,
.-update,
.-pencil,
.-post,
.-write::before {
  content: '\e8c3';
} /* '' */
.icon-exclamation,
.-caution,
.-circular,
.-alert,
.-warning::before {
  content: '\e8c4';
} /* '' */
.icon-expand,
.-maximise,
.-send,
.-transfer,
.-share::before {
  content: '\e8c5';
} /* '' */
.icon-factory,
.-building,
.-industry,
.-manufacture,
.-warehouse::before {
  content: '\e8c6';
} /* '' */
.icon-female,
.-sex,
.-gender,
.-girl,
.-woman::before {
  content: '\e8c7';
} /* '' */
.icon-fire,
.-trending,
.-hot,
.-flame,
.-energy::before {
  content: '\e8c8';
} /* '' */
.icon-flag,
.-country,
.-nation,
.-spam,
.-report::before {
  content: '\e8c9';
} /* '' */
.icon-flash::before,
.-recharge,
.-thunder,
.-spark,
.-fast::before {
  content: '\e8ca';
} /* '' */
.icon-fly,
.-send,
.-paper,
.-submit,
.-plane::before {
  content: '\e8cb';
} /* '' */
.icon-folder.-file,
.-document,
.-organize,
.-storage::before {
  content: '\e8cc';
} /* '' */
.icon-gear,
.-settings,
.-update,
.-setup,
.-config::before {
  content: '\e8cd';
} /* '' */
.icon-gift,
.-reward,
.-bonus,
.-present,
.-christmas::before {
  content: '\e8ce';
} /* '' */
.icon-heart,
.-like,
.-love,
.-favourite,
.-dating::before {
  content: '\e8cf';
} /* '' */
.icon-hexagon,
.-polygon,
.-geometrical,
.-shape,
.-6-sided::before {
  content: '\e8d0';
} /* '' */
.icon-home,
.-house,
.-building,
.-hut,
.-shelter::before {
  content: '\e8d1';
} /* '' */
.icon-house,
.-home,
.-shelter,
.-building,
.-hut::before {
  content: '\e8d2';
} /* '' */
.icon-key,
.-password,
.-lock,
.-privacy,
.-login::before {
  content: '\e8d3';
} /* '' */
.icon-lock,
.-open,
.-unsecured,
.-unlocked,
.-unlock,
.-::before {
  content: '\e8d4';
} /* '' */
.icon-lock,
.-secure,
.-safety,
.-safe,
.-protect::before {
  content: '\e8d5';
} /* '' */
.icon-magnifier,
.search,
.discover,
.zoom,
.lens::before {
  content: '\e8d6';
} /* '' */
.icon-mailbox,
.-box,
.-letter,
.-mail,
.-post::before {
  content: '\e8d7';
} /* '' */
.icon-male,
.-sex,
.-gender,
.-boy,
.-man::before {
  content: '\e8d8';
} /* '' */
.icon-minus,
.-remove,
.-delete,
.-button,
.-uninstall::before {
  content: '\e8d9';
} /* '' */
.icon-network,
.-independent,
.-group,
.-community,
.-people::before {
  content: '\e8da';
} /* '' */
.icon-network,
.-share,
.-connect,
.-community,
.-group::before {
  content: '\e8db';
} /* '' */
.icon-new,
.-artboard,
.-file,
.-create,
.-post::before {
  content: '\e8dc';
} /* '' */
.icon-notification,
.-reminder,
.-activity,
.-alert,
.-new-message::before {
  content: '\e8dd';
} /* '' */
.icon-paint,
.-brush,
.-themes,
.-roller,
.-hand-roller::before {
  content: '\e8de';
} /* '' */
.icon-paint,
.-color,
.-palette,
.-art,
.-drawing::before {
  content: '\e8df';
} /* '' */
.icon-pencil,
.-design,
.-draw,
.-mark,
.-write::before {
  content: '\e8e0';
} /* '' */
.icon-point-up,
.-gesture,
.-hand-up,
.-top,
.-finger::before {
  content: '\e8e1';
} /* '' */
.icon-power,
.-on,
.-stand-by,
.-switch,
.-button::before {
  content: '\e8e2';
} /* '' */
.icon-prism,
.-pyramid,
.-geometry,
.-tresd-shape,
.-triangle::before {
  content: '\e8e3';
} /* '' */
.icon-prize,
.-award,
.-merit,
.-reward,
.-trophy::before {
  content: '\e8e4';
} /* '' */
.icon-question-mark,
.-circle,
.-sign,
.-more,
.-info::before {
  content: '\e8e5';
} /* '' */
.icon-reward,
.-tag,
.-ribbon,
.-premium,
.-quality::before {
  content: '\e8e6';
} /* '' */
.icon-safety,
.-lifesaver,
.-protection,
.-insurance,
.-lifeguard::before {
  content: '\e8e7';
} /* '' */
.icon-scan,
.-bar-code,
.-qr-code,
.-barcode,
.-scanner::before {
  content: '\e8e8';
} /* '' */
.icon-share,
.-network,
.-connect,
.-community,
.-media::before {
  content: '\e8e9';
} /* '' */
.icon-shield,
.-caution,
.-alert,
.-warning,
.-not-working::before {
  content: '\e8ea';
} /* '' */
.icon-shield,
.-disabled,
.-trojan,
.-virus,
.-error::before {
  content: '\e8eb';
} /* '' */
.icon-shield,
.-safe,
.-protection,
.-hands-free,
.-anti-virus::before {
  content: '\e8ec';
} /* '' */
.icon-sort,
.-filter,
.-arrange,
.-funnel,
.-filter::before {
  content: '\e8ed';
} /* '' */
.icon--mark,
.-highlight,
.-bookmark,
.-save::before {
  content: '\e8ee';
} /* '' */
.icon-stop,
.-disabled,
.-not-allowed,
.-close,
.-exit::before {
  content: '\e8ef';
} /* '' */
.icon-tag,
.-shopping,
.-price,
.-label,
.-search::before {
  content: '\e8f0';
} /* '' */
.icon-telescope,
.-magnifier,
.-space,
.-optical-instrument,
.-discover::before {
  content: '\e8f1';
} /* '' */
.icon-thumbs-down,
.-dislike,
.-disagree,
.-not-approved,
.-fail::before {
  content: '\e8f2';
} /* '' */
.icon-thumbs-up::before,
.-approve,
.-favourite,
.-like,
.-agree::before {
  content: '\e8f3';
} /* '' */
.icon-tick,
.-check-mark,
.-accept,
.-verified,
.-done::before {
  content: '\e8f4';
} /* '' */
.icon-tick,
.-checked,
.-accept,
.-verified,
.-done::before {
  content: '\e8f5';
} /* '' */
.icon-ticket,
.-event,
.-raffle,
.-movie,
.-paper::before {
  content: '\e8f6';
} /* '' */
.icon-torch,
.-pocket,
.-lantern,
.-light,
.-electric::before {
  content: '\e8f7';
} /* '' */
.icon-umbrella,
.-shelter,
.-insurance,
.-cover,
.-rain::before {
  content: '\e8f8';
} /* '' */
.icon-user,
.-person,
.-avtar,
.-profile-picture,
.-dp::before {
  content: '\e8f9';
} /* '' */
.icon-verified,
.-quality,
.-checked,
.-badge,
.-reward::before {
  content: '\e8fa';
} /* '' */
.icon-view,
.-disabled,
.-no-view,
.-not-visible,
.-invisible::before {
  content: '\e8fb';
} /* '' */
.icon-view::before,
.-eye,
.-seen,
.-show,
.-vision::before {
  content: '\e8fc';
} /* '' */
.icon-zoom-in,
.-big,
.-enlarge,
.-plus,
.-maximize::before {
  content: '\e8fd';
} /* '' */
.icon-zoom-out,
.-decrease,
.-minus,
.-minimize,
.-reduce::before {
  content: '\e8fe';
} /* '' */
.icon-analytics-file,
.-line-chart,
.-format,
.-report,
.-paper::before {
  content: '\e8ff';
} /* '' */
.icon-approve,
.-file,
.-check,
.-verified,
.-tick::before {
  content: '\e900';
} /* '' */
.icon-binary,
.-file,
.-encrypted,
.-protected,
.-secured::before {
  content: '\e901';
} /* '' */
.icon-configuration-file,
.-document-file,
.-setting,
.-option,
.-config::before {
  content: '\e902';
} /* '' */
.icon-contact,
.-identity,
.-person,
.-user,
.-profile::before {
  content: '\e903';
} /* '' */
.icon-database-file,
.-web,
.-transfer,
.-file,
.-data-base::before {
  content: '\e904';
} /* '' */
.icon-document-unknown,
.-question-mark,
.-paper-file,
.-question,
.-file::before {
  content: '\e905';
} /* '' */
.icon-document,
.-format,
.-up,
.-upload,
.-file-upload::before {
  content: '\e906';
} /* '' */
.icon-download,
.-save,
.--install,
.--setup,
.-file-download::before {
  content: '\e907';
} /* '' */
.icon-duplicate-file,
.-copy-file,
.-double-file,
.-multiply,
.-document::before {
  content: '\e908';
} /* '' */
.icon-edit-file-pen,
.-file-pen,
.-copy,
.-modify,
.-write::before {
  content: '\e909';
} /* '' */
.icon-edit-file,
.-editing,
.-edit-document,
.-edit,
.-editor::before {
  content: '\e90a';
} /* '' */
.icon-excel-sheet,
.-file,
.-spread-sheet,
.-presentation::before {
  content: '\e90b';
} /* '' */
.icon-file-cloud,
.-cloud-file,
.-cloud,
.-remote,
.-remote-file::before {
  content: '\e90c';
} /* '' */
.icon-file-code-html-programming,
.-html-file,
.-html-coding,
.-extension-file,
.-web-development::before {
  content: '\e90d';
} /* '' */
.icon-file-copy-to-clipboard,
.-clip-board,
.-document,
.-file,
.-copy,
.-sign::before {
  content: '\e90e';
} /* '' */
.icon-file-download,
.-file,
.-down,
.-document,
.-download::before {
  content: '\e90f';
} /* '' */
.icon-file-equal,
.-compare,
.-file-comparison,
.-file,
.-equal-file::before {
  content: '\e910';
} /* '' */
.icon-file-error-exclamation-alert,
.-attention-document,
.-error,
.-alert,
.-document::before {
  content: '\e911';
} /* '' */
.icon-file-graphic,
.-file-vector,
.-nib-file,
.-pen-tool,
.-pen-nib-::before {
  content: '\e912';
} /* '' */
.icon-file-loading-progressing,
.-file-loading,
.-shape,
.-sign,
.-loading-file::before {
  content: '\e913';
} /* '' */
.icon-file-minus,
.-document,
.-subtract,
.-minus,
.-report::before {
  content: '\e914';
} /* '' */
.icon-file-picture,
.-camera,
.-file-image,
.-profile,
.-photo::before {
  content: '\e915';
} /* '' */
.icon-file-plus,
.-add,
.-document-plus,
.-plus,
.-files::before {
  content: '\e916';
} /* '' */
.icon-file-setting,
.-cog,
.-modify,
.-setting-file,
.-settings::before {
  content: '\e917';
} /* '' */
.icon-file-sound,
.-sound-file,
.-audio-sound,
.-speaker,
.-volume::before {
  content: '\e918';
} /* '' */
.icon-file-sync-update,
.-update-document,
.-sync,
.-synchronise,
.-file,
.-update::before {
  content: '\e919';
} /* '' */
.icon-file-uploaded-submitted-tick-mark,
.folder-checked,
.-approved,
.-verified,
.-confirm::before {
  content: '\e91a';
} /* '' */
.icon-file-view-visible,
.-see,
.-visible,
.-view,
.-visibility::before {
  content: '\e91b';
} /* '' */
.icon-file,
.-art,
.-drawing,
.-vector,
.-illustrator::before {
  content: '\e91c';
} /* '' */
.icon-file,
.-attachment,
.-document,
.-pins,
.-paperclip::before {
  content: '\e91d';
} /* '' */
.icon-file,
.-caution,
.-alert,
.-warning,
.-exclamation::before {
  content: '\e91e';
} /* '' */
.icon-file,
.-disabled,
.-not-allowed,
.-no-permission,
.-no-access::before {
  content: '\e91f';
} /* '' */
.icon-file,
.-empty,
.-file,
.-documentation,
.-doc::before {
  content: '\e920';
} /* '' */
.icon-file,
.-graph,
.-bar-chart,
.-stats,
.-analytics,
.-reports::before {
  content: '\e921';
} /* '' */
.icon-file,
.-html,
.-code,
.-script,
.-program::before {
  content: '\e922';
} /* '' */
.icon-file,
.-link,
.-chain,
.-url,
.-web::before {
  content: '\e923';
} /* '' */
.icon-file,
.-locked,
.-protected,
.-secured,
.-encrypted::before {
  content: '\e924';
} /* '' */
.icon-file,
.-options,
.-list,
.-agenda,
.-tasks::before {
  content: '\e925';
} /* '' */
.icon-file,
.-refresh,
.-reload,
.-update,
.-sync::before {
  content: '\e926';
} /* '' */
.icon-file,
.-remove,
.-delete,
.-error,
.-corrupt--::before {
  content: '\e927';
} /* '' */
.icon-file,
.-share,
.-sharing,
.-social,
.-connect::before {
  content: '\e928';
} /* '' */
.icon-file,
.-shield,
.-secured,
.-protected,
.-safe,
.-virus-free::before {
  content: '\e929';
} /* '' */
.icon-files,
.-agreement,
.-notes,
.-docs,
.-pages::before {
  content: '\e92a';
} /* '' */
.icon-folder-document-report,
.-file-report,
.-archive,
.-document,
.-report::before {
  content: '\e92b';
} /* '' */
.icon-folder-lock,
.-locked-file,
.-safe-folder,
.-secure-folder,
.-locked-folder::before {
  content: '\e92c';
} /* '' */
.icon-folder-search,
.-search-folder,
.-archive,
.-think,
.-look,
.-find,
.-through::before {
  content: '\e92d';
} /* '' */
.icon-folder-sync,
.-data,
.-folder,
.-recovery,
.-sync::before {
  content: '\e92e';
} /* '' */
.icon-heart-file,
.-love-letter,
.-favourite,
.-file,
.-bookmark-file,
.-favourite::before {
  content: '\e92f';
} /* '' */
.icon-history-file,
.-file-waiting,
.-past,
.-archive,
.-file::before {
  content: '\e930';
} /* '' */
.icon-invoice-document-file,
.-bill,
.-business,
.-invoice,
.-document-::before {
  content: '\e931';
} /* '' */
.icon-key-file,
.key-chain,
.-key-document,
.-file,
.-safety-file::before {
  content: '\e932';
} /* '' */
.icon-layers,
.-file,
.-doc,
.-multi-layer,
.-database::before {
  content: '\e933';
} /* '' */
.icon-left-arrow,
.-file-left,
.-left,
.-document,
.-send,
.-arrow::before {
  content: '\e934';
} /* '' */
.icon-lock,
.-file,
.-unprotected,
.-unlocked,
.-insecure::before {
  content: '\e935';
} /* '' */
.icon-move-file,
.-file-moved,
.-export,
.-document,
.-send-file::before {
  content: '\e936';
} /* '' */
.icon-music-file,
.-play-sound,
.-notes,
.-music,
.-mp3::before {
  content: '\e937';
} /* '' */
.icon-network-folder,
.-network,
.-file,
.-data,
.-internet,
.-folder::before {
  content: '\e938';
} /* '' */
.icon-new-file,
.-document,
.-record,
.-page,
.-upload::before {
  content: '\e939';
} /* '' */
.icon-pen,
.-pencil,
.-edit,
.-update,
.-write::before {
  content: '\e93a';
} /* '' */
.icon-picture-file,
.-media,
.-photo-file,
.--picture,
.-gallery::before {
  content: '\e93b';
} /* '' */
.icon-pie-chart-file,
.-pie-chart,
.-presentation,
.-report,
.-document,
.-file::before {
  content: '\e93c';
} /* '' */
.icon-play-file,
.-play-music,
.-play-video,
.-play,
.-media::before {
  content: '\e93d';
} /* '' */
.icon-remove-file,
.-delete,
.-document,
.-remove,
.-crest,
.-minus::before {
  content: '\e93e';
} /* '' */
.icon-right-arrow,
.-file-right,
.-right,
.-do-right,
.-document,
.-receive::before {
  content: '\e93f';
} /* '' */
.icon-scissor,
.-cut,
.-delete,
.-file,
.-remove::before {
  content: '\e940';
} /* '' */
.icon-search-file-,
.-file-search,
.-search-,
.find-file,
.-view-file::before {
  content: '\e941';
} /* '' */
.icon-send-file,
.-move-file,
.-copy-data,
.-copy-file,
.-copy-document::before {
  content: '\e942';
} /* '' */
.icon-setup-file,
.-cog,
.-gear,
.-install,
.-config::before {
  content: '\e943';
} /* '' */
.icon-songs,
.-stored,
.-mp3,
.-disk,
.-audio::before {
  content: '\e944';
} /* '' */
.icon-starred-file,
.-document,
.-star,
.--favourite,
.-file::before {
  content: '\e945';
} /* '' */
.icon-text,
.-file,
.-document,
.-notepad,
.-doc--::before {
  content: '\e946';
} /* '' */
.icon-upload,
.-send,
.-file,
.-sync,
.-update,
.-file-transfer::before {
  content: '\e947';
} /* '' */
.icon-vector,
.-file,
.-sketch,
.-illustrator,
.-pen::before {
  content: '\e948';
} /* '' */
.icon-zip-file,
.-archive-file,
.-archive-zip,
.-zip-extension,
.-zip::before {
  content: '\e949';
} /* '' */
.icon-dapps::before {
  content: '\e94a';
} /* '' */
.icon-reload::before {
  content: '\e83a';
} 