@import "../../assets/styles/designSystem.scss";

body {
  color: $white;
  height: 100vh;
  background-color: $black;
  font-family: Rubik, Montserrat, Arial, Helvetica, sans-serif;
}

.main {
  > .header {
    margin-bottom: 40px;
    padding-top: 20px;

    &.white {
      border-radius: 4px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
      background: #fff;
      flex-direction: column;
    }

    .logobtc {
      float: none;
      position: relative;
      display: block;
      margin: 10px auto 15px;
      width: 100px;
    }

    h1 {
      text-align: center;
      font-size: 28px;
      margin-bottom: 20px;
    }

    h2 {
      text-align: center;
      font-size: 26px;
      margin-bottom: 40px;
      display: block;
      width: 100%;
    }

    h3 {
      font-size: 1.15rem;
      margin: 0;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 13px;
      margin-top: 0.5rem;
    }

    p {
      padding: 0px 0 15px 0px;
      margin: 0;
      font-size: 13px;
      line-height: 16px;
    }

    a.read-more {
      border-radius: 5px;
      background-color: transparent;
      padding: 0;
      font-size: 13px;
      text-decoration: none;
      color: #ff4465;
    }
  }
}

span {
  text-align: right;
  font-size: 16px;
  line-height: 16px;
}

p {
  padding: 10px 0 0 0px;
  margin: 0;
  font-size: 14px;
  line-height: 17px;

  strong {
    font-weight: 500;
  }
}

.mark,
mark {
  background-color: #7d3ff1;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
}

.disclaimer-message {
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
.disclaimer-message p {
  line-height: 18px;
  margin: 0;
  padding: 10px;
  font-size: 12px;
}

.disclaimer-message a {
  border-radius: 5px;
  background-color: #ff4465;
  padding: 2px 10px 2px 10px;
  color: #fff;
}

.modal-content {
  background-color: #2e2f47;
}

.modal-title {
  color: #fff;
  font-size: 15px;
}

.modal-header {
  border-bottom: 1px solid #41435f;
}

.modal-footer {
  border-top: 1px solid #41435f;
}

.modal-header .close {
  color: #fff;
  text-shadow: 0 1px 0 #000;
  opacity: 1;
}

.modal-body h3 {
  color: #fff;
  font-size: 13px;
  margin-bottom: 20px;
  margin-top: 0px;
}

.modal-backdrop.show {
  opacity: 0.85;
}

.cards-wrapper {
  width: 100%;
  margin: 0 auto;
}

.cards-wrapper hr {
  border: 0;
  border-top: 1px solid #41435f;
}

.card-info {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #41435f;
  margin-bottom: 20px;
  padding: 20px 0 0 0;
}

.card-info h2 {
  text-align: center;
  width: 100%;
  font-size: 23px;
  margin-bottom: 20px;
}

.card-info .title {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  font-stretch: normal;
  line-height: 1.1;
  -webkit-letter-spacing: 0.55px;
  -moz-letter-spacing: 0.55px;
  -ms-letter-spacing: 0.55px;
  letter-spacing: 0.55px;
  color: #7476a2;
  padding-bottom: 5px;
}

.card-info .label {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  font-stretch: normal;
  line-height: 1.1;
  -webkit-letter-spacing: 0.55px;
  -moz-letter-spacing: 0.55px;
  -ms-letter-spacing: 0.55px;
  letter-spacing: 0.55px;
  color: #7476a2;
  padding-bottom: 15px;
}

.card-info .balance {
  font-weight: 500;
  font-size: 32px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.card-info .notice {
  display: inline-block;
  padding: 10px 0;
  font-size: 14px;
  color: #7476a2;
  text-align: left;
  line-height: 17px;
  margin-top: 0px;
}

.copied-label {
  text-align: center;
  font-size: 11px;
  padding: 0;
}

.account-totals .balance {
  font-weight: 500;
  font-size: 36px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0 0 40px;
}

.account-totals .label {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  font-stretch: normal;
  line-height: 1.1;
  -webkit-letter-spacing: 0.55px;
  -moz-letter-spacing: 0.55px;
  -ms-letter-spacing: 0.55px;
  letter-spacing: 0.55px;
  color: #7476a2;
  margin-bottom: 10px;
}

.btc-address-info {
  padding-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  max-width: 300px;
  width: 100%;
}

.qrcode-wrapper {
  margin: 0 auto;
  border-radius: 6px;
  border: 1px solid #b620e0;
  display: inline-block;
  padding: 10px;
  font-size: 0;
}

.qrcode-wrapper canvas {
  max-width: 100% !important;
  width: 100% !important;
  height: auto !important;
}

.btc-address-label {
  display: inline-block;
  padding: 10px;
  font-size: 11px;
  color: #7476a2;
  text-align: center;
  line-height: 12px;
}

.btn-btc-address {
  font-size: 13px !important;
  height: 32px !important;
  padding: 0;
  width: 100% !important;
  margin-top: 10px;
}

.btn-btc-address i {
  font-size: 14px;
  margin-right: 5px;
}

.form-check {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0;
}

.form-check-input {
  position: relative;
  margin-top: 0;
  margin-left: 0;
  margin-right: 5px;
}

.form-check-label {
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 24px;
  width: 100%;
}

fieldset {
  border: 1px solid #7b7db2;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  position: relative;
  margin-bottom: 0px;
  box-sizing: content-box;
}

legend {
  color: #7b7db2;
  display: block;
  -webkit-padding-start: 2px;
  padding-inline-start: 2px;
  -webkit-padding-end: 2px;
  padding-inline-end: 2px;
  border-width: medium;
  border-width: initial;
  border-style: none;
  border-color: currentColor;
  border-color: initial;
  border-image: none;
  border-image: initial;
  text-align: left;
  width: auto;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 0 10px;
  font-size: 10px;
  line-height: 15px;
}

fieldset input {
  border: none;
  width: 100%;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  background-color: transparent;
  text-align: left;
  outline: none;

  padding: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::placeholder {
  color: #66688f;
  opacity: 1;
  font-weight: 100;
}

.input-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.btn-tw {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #aa33b5;
  color: #ffffff;
  font-size: 14px;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: opacity 0.3s;
  outline: none;
}

.btn-tw.disabled {
  cursor: default;
  opacity: 0.3;
}

.btn-small {
  width: 50px;
  height: 50px;
  margin-top: 5px;
}

.footer {
  width: 100%;
}

.footer .warning {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.55px;
  color: #7476a2;
  padding-bottom: 30px;
  margin-top: 10px;
}
.footer .warning span {
  font-weight: 100;
  font-size: 21px;
}

.transaction-list {
  margin-top: 50px;
  position: relative;
  min-height: 480px;
}

.transaction {
  width: 100%;
  border-left: 5px solid $greenUfo;
  padding: 15px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: $gunMetal;
  margin-bottom: 5px;
  border-top-left-radius: 5px;
}

.transaction-list.loading .transaction {
  opacity: 0.3;
}

.transaction p {
  padding: 5px 0 5px 0;
}

.transaction .type {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 6px;
  margin-top: 0px;
}

.transaction .type .label {
  padding: 4px 8px;
  border-radius: 5px;
  background-color: $blueDark;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  white-space: nowrap;
}

.transaction .confirmation {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 6px;
  margin-top: 5px;
}

.transaction .confirmation .label {
  color: $white;
  font-weight: 700;
}

.transaction-list .date,
.transaction .confirmation .date {
  color: $grayMetal;
}

.transaction .txlink {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.transaction .txlink a {
  color: $white;
  cursor: pointer;
}

.btn-reload {
  background: #2e2f47;
  border: 0;
  border-radius: 50%;
  cursor: pointer !important;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
}
.btn-reload:focus {
  outline: none;
}

.swal2-popup {
  background: #2e2f47;
}

.swal2-popup .swal2-title {
  display: block;
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #fff;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  fieldset {
    border: 0;
    border-radius: 4px;
    padding: 0px;
    width: 100%;
    position: relative;
    margin-bottom: 0px;
    box-sizing: content-box;
  }

  fieldset input {
    font-size: 16px;
    height: 40px;
    padding: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 10px;
  }

  .cards-wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 425px) {
  .main > .header {
    margin-bottom: 20px;
    padding-top: 20px;
  }

  .main > .header .logobtc {
    float: none;
    position: relative;
    display: block;
    margin: 10px auto 15px;
    width: 60px;
  }

  .main > .header h1 {
    text-align: center;
    font-size: 23px;
    margin-bottom: 20px;
  }

  .cards-wrapper {
    width: 100%;
    margin: 0 auto;
  }

  .card-info .balance {
    font-size: 28px;
  }

  .card-info .notice {
    display: inline-block;
    padding: 10px 0;
    font-size: 11px;
    color: #7476a2;
    text-align: left;
    line-height: 12px;
  }

  .account-totals .label {
    font-size: 11px;
  }

  .account-totals .balance {
    font-size: 19px;
  }
}
