$white: #ffffff;
$whiteSmoke: #f7f7f7;
$blueShadow: #eeeff6;
$gunMetal: #2e2f47;
$darkGunMetal: #353656;
$isabelline: #ebebeb;
$isabellineDark: #e0e2e4;
$blueGray: #383955;
$blueLight: #3face7;
$blueDark: #4a69e2;
$pinkCerise: #ee3f71;
$purpleShiny: #7d3ff1;
$purpleFaded: #88579c;
$purpleLight: #a087ee;
$purpleDark: #4c3594;
$manatee: #9c9eb9;
$magicPotion: #ff4465;
$blueYankees: #25253a;
$blackRich: #10111d;
$greenUfo: #33d46c;
$black: #191a2a;
$blackDark: #212233;
$blackRgba5: rgba(0, 0, 0, 0.5);
$blackRgba15: rgba(0, 0, 0, 0.15);
$dark95: rgba(25, 26, 42, 0.95);
$gray: #66688f;
$grayLight: #787aa3;
$grayDark: #3f415d;
$grayMetal: #7476a2;
$strokeGray: #dddee7;
$purpleLinear:linear-gradient(135deg, rgb(160, 42, 140) 0%, rgb(106, 41, 197) 100%);

$xs: 400px;
$sm: 576px;
$md: 728px;
$lg: 992px;
$lm: 1100px;
$xl: 1200px;

$tiny: 9px;
$small: 11px;
$regular: 12px;
$menu: 13px;
$large: 14px;
$xLarge: 16px;
$regularLarge: 20px;
$xxLarge: 24px;
$menuLarge: 28px;
$xxxLarge: 36px;
